import React from "react";
import Apspage from "./Components/Apspage";

const App = () => {
  return (
    <div>
      <Apspage />
    </div>
  );
};

export default App;
